import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import AppButtonActions from "@uni/core/AppButtonAction";
import AppCard from "@uni/core/AppCard";
import AppPageMetadata from "@uni/core/AppPageMetadata";
import AppsContainer from "@uni/core/AppsContainer";
import AppsContent from "@uni/core/AppsContainer/AppsContent";
import AppsHeader from "@uni/core/AppsContainer/AppsHeader";
import AppTableContainer from "@uni/core/AppTableContainer";
import { URL_API } from "@uni/services/endpoint";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import { Pagination } from "antd";
import useGetAllHistory from "hooks/apis/history/useGetHistory";
import useAppShowHideTableColumn from "hooks/UseAppShowHideTableColumn";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { LimitDefault, OffsetDefault } from "shared/constants/AppConst";
import "./index.style.less";
import useGetHistoryColumn from "./useGetHistoryColumn";
import IntlMessages from "@uni/utility/IntlMessages";
import useAppConfirmModal from "hooks/useAppConfirmModal";
import useDeleteAllHistory from "hooks/apis/history/useDeleteAllHistory";

const TABLE_KEY = "HistoryTable";
const History = () => {
  const { messages } = useIntl();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const { companyManagement } = useLayoutContext();
  const [params, setParams] = useState({
    limit: LimitDefault,
    offset: OffsetDefault,
    search: "",
  });
  const queryClient = useQueryClient();
  const { mutateAsync: mutateDeleteAll } = useDeleteAllHistory();

  const { data: dataHistory, isFetching: isFetchingHistory } = useGetAllHistory(
    {
      ...params,
      folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
      company_slug: companyManagement?.company?.detail?.company?.slug || "",
    }
  );

  const columnConfig = useGetHistoryColumn();

  const { filteredTableColumns, tableColumnDisplayConfigurator } =
    useAppShowHideTableColumn({
      tableKey: TABLE_KEY,
      tableColumns: columnConfig,
    });

  const [columnsState, setColumnsState] = useState(() =>
    columnConfig.map((column, i) => ({
      ...column,
      key: `${i}`,
      onHeaderCell: () => ({
        id: `${i}`,
        draggable: column.draggable !== false,
      }),
      onCell: () => ({
        id: `${i}`,
      }),
    }))
  );

  const { contextHolder: contextConfirmDelete, openModal: openConfirmDelete } =
    useAppConfirmModal();

  const onDeleteAll = useCallback(() => {
    openConfirmDelete({
      title: <IntlMessages id="data.exim.statement15.deleteConfirm" />,
      okText: <IntlMessages id="common.delete" />,
      cancelText: <IntlMessages id="common.cancel" />,
      onOk: () => {
        mutateDeleteAll({
          folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
          company_slug: companyManagement?.company?.detail?.company?.slug || "",
        });
      },
    });
  }, [mutateDeleteAll, openConfirmDelete]);

  return (
    <>
      {" "}
      <AppPageMetadata title={messages["sidebar.determination.history"]} />
      <AppCard
        title={messages["sidebar.determination.history"]}
        className="single-tab-card-header"
      >
        <AppsContainer type="bottom" fullView>
          <AppsHeader>
            <div
              className="page-header"
              style={{ justifyContent: "flex-start" }}
            >
              <AppButtonActions
                // scopes={[PERMISSIONS.CREATE_TAG]}
                icon={<ReloadOutlined />}
                title={messages["common.refresh"]}
                type="text"
                onClick={() =>
                  queryClient.invalidateQueries(URL_API.HISTORY.GET_ALL)
                }
              />

              <AppButtonActions
                icon={<DeleteOutlined />}
                title={messages["common.deleteAll"]}
                type="text"
                onClick={onDeleteAll}
                // scopes={[PERMISSIONS.DELETE_ALL_ECUS]}
              />
            </div>
          </AppsHeader>
          <AppsContent
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <AppTableContainer
              loading={isFetchingHistory}
              data={dataHistory?.results || []}
              columns={filteredTableColumns}
              columnsState={columnsState}
              setColumnsState={setColumnsState}
              // className="table-wrapper"
            />
          </AppsContent>
          <div className="custom-pagination">
            <Pagination
              defaultPageSize={20}
              current={Math.ceil(params.offset / params.limit) + 1}
              onChange={(page, pageSize) => {
                setParams((prev) => {
                  return {
                    ...prev,
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                  };
                });
              }}
              total={dataHistory?.count}
              showTotal={(total, range) => {
                return (
                  <p>
                    {range[0]}-{range[1]} {messages["common.on"]} {total}{" "}
                    {messages["common.records"]?.toString()}
                  </p>
                );
              }}
              showSizeChanger
            />
          </div>
        </AppsContainer>
      </AppCard>
      {contextConfirmDelete}
    </>
  );
};

export default History;
