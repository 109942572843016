import CryptoJS from "crypto-js";
import moment from "moment";
import {
  APP_UPLOAD_FILE_MIME_TYPES,
  DEFAULT_COLUMN_SORT,
  LimitDefault,
  PageDefault,
} from "shared/constants/AppConst";
import { GENDERS, SortType } from "../../shared/constants/AppEnums";
import UserInfoItem from "../core/AppViewItems/UserActionInfoItem/UserInfoItem";
import { notification } from "antd";

export const checkPermissionGranted = ({ permissions = [], scopes = [] }) => {
  return permissions.some(
    (permission) =>
      !scopes || scopes.length === 0 || scopes.includes(permission)
  );
};

export const filterPermissionGrantedList = ({
  permissions = [],
  listItems = [],
}) => {
  return listItems.filter((item) =>
    checkPermissionGranted({ permissions, scopes: item.scopes })
  );
};

export const convertToS3Link = (type, id, s3String) => {
  if (!s3String) return null;
  return `${process.env.REACT_APP_LINK_S3}${type}${
    id ? `_${id}` : ""
  }/${s3String}`;
};

export const reloadImg = async (url) => {
  await fetch(url, { cache: "reload", mode: "no-cors" });
  document.body
    .querySelectorAll(`img[src='${url}']`)
    .forEach((img) => (img.src = url));
};

export const convertDateTime = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY h:mmA");
};

export const convertDate = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY");
};

export const convertDateUni = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("YYYY-MM-DD");
};

export const convertGender = (number) => {
  return GENDERS.find((gender) => gender.id === number)?.key;
};

export const formatDate = (date) => {
  let dateMoment;
  try {
    dateMoment = moment(date);
  } catch (e) {
    dateMoment = moment();
  }

  return dateMoment.subtract(1, "days").format();
};

export const formatTime = (time) => {
  let timeMoment;
  try {
    timeMoment = moment(time);
  } catch (e) {
    timeMoment = moment();
  }

  return timeMoment.subtract(1, "days").format("H:MM");
};

export const testImage = (url) => {
  return new Promise((resolve, reject) => {
    const tester = document.createElement("img");
    tester.addEventListener("error", () => reject("error"));
    tester.addEventListener("load", () => resolve("load"));
    tester.src = url;
  });
};

export const getEditedSingleImageInfo = (value) => {
  return {
    isEdited: value && typeof value === "object",
    isDeleted: !value,
  };
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const convertDollarString = (value) => {
  const valueNumber = Number(value);

  return (Number.isNaN(valueNumber) ? 0 : valueNumber).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const convertRawCategoriesToTreeDataFormat = ({
  categories = [],
  parent = null,
}) =>
  categories
    .filter((item) => item.parent === parent)
    .map(({ name, id }) => ({
      title: name,
      value: id,
      children: convertRawCategoriesToTreeDataFormat({
        categories,
        parent: id,
      }),
    }));

export const CryptoJsAES = {
  decode: (encrypted) =>
    CryptoJS.AES.decrypt(
      encrypted?.replaceAll("-", "/"),
      process.env.REACT_APP_CRYPTO_JS_SECRET ?? ""
    ).toString(CryptoJS.enc.Utf8),
  encode: (data) =>
    CryptoJS.AES.encrypt(data, process.env.REACT_APP_CRYPTO_JS_SECRET ?? "")
      .toString()
      ?.replaceAll("/", "-"),
};

export const filterByText = ({ value, input }) =>
  (value ?? "").toLowerCase().includes(input.toLowerCase());

export const formatUserName = ({ firstName, lastName, username }) => {
  if (!firstName && !lastName) {
    return username;
  }
  return `${firstName ?? ""} ${lastName ?? ""} `;
};

export const getContentFromNamePath = ({ value, namePath }) => {
  if (!namePath) return null;
  else if (typeof namePath === "string") return value?.[namePath];
  else return namePath.reduce((prev, curr) => prev?.[curr], value);
};

export const getSelectUserCommonProps = ({ users = [] }) => {
  const options = users.map(
    ({
      id,
      firstName,
      lastName,
      username,
      code,
      s3Profile,
      phoneNumber,
      email,
    }) => {
      const name =
        firstName || lastName
          ? `${firstName ?? ""} ${lastName ?? ""}`
          : username ?? "";
      return {
        label: (
          <UserInfoItem
            id={id}
            code={code}
            s3Profile={s3Profile}
            username={name}
            isSizeSmall={true}
            isShowContactInfo={true}
            phoneNumber={phoneNumber}
            email={email}
          />
        ),
        value: id,
        name,
        code,
        phoneNumber,
        email,
      };
    }
  );
  const filterOption = (input, option) =>
    filterByText({
      value: option?.name,
      input,
    }) ||
    filterByText({ value: option?.code, input }) ||
    filterByText({
      value: option?.phoneNumber,
      input,
    }) ||
    filterByText({ value: option?.email, input });
  return {
    options,
    filterOption,
    optionLabelProp: "name",
  };
};

export const getToast = ({ type, message, data, style }) => {
  const openNotification = () => {
    notification[type]({
      style: {
        background: "#000",
      },
      className: style,
      message: message,
      description: (
        <ul>
          <li>Total Data: {data?.total_cells || 0} Declarations</li>
          <li>Duplicate Data: {data?.duplicated_cells || 0} Declarations</li>
          <li>Final Data: {data?.final_update || 0} Declarations</li>
        </ul>
      ),
    });
  };
  return { openNotification };
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileInfoFromFileLink = (fileLink = "") => {
  const name = fileLink.split("_").pop();
  const fileExtension = name.split(".").pop();
  let type;
  switch (fileExtension) {
    case "doc":
      type = APP_UPLOAD_FILE_MIME_TYPES.WORD[0];
      break;
    case "pdf":
      type = APP_UPLOAD_FILE_MIME_TYPES.PDF[0];
      break;
    case "xls":
    case "xlsx":
    case "ods":
      type = APP_UPLOAD_FILE_MIME_TYPES.EXCEL[0];
      break;
    default:
      type = APP_UPLOAD_FILE_MIME_TYPES.IMAGE[0];
  }
  // console.log({ type });
  return {
    name,
    type,
  };
};

export const errorHandlerMessage = (err) => {
  const errValue = Object.values(err).join(", ");
  return errValue;
};

export const excelIndex = (cell) => {
  let col = cell?.match(/[A-Za-z]+/)[0];
  let row = cell?.match(/\d+/i)[0];

  return {
    col: col.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1,
    row: row - 1,
  };
};

export const isValidFormatCellValue = (str) => {
  const regex = /^[A-Za-z]+\d+$/;
  return regex.test(str);
};

export const removeNewlines = (item) => {
  const regex = /(\r\n|\n|\r)/g;
  return String(item)?.replace(regex, "");
};

export const convertDataHeader = (data) => {
  if (data?.length === 1) {
    return data[0].map((item) => (item ? removeNewlines(item).trim() : ""));
  } else {
    const result = [];
    for (let i = 0; i < data[data.length - 1]?.length; i++) {
      if (data[data.length - 1][i]) {
        result.push(
          `${removeNewlines(data[data.length - 2][i]).trim()}|${removeNewlines(
            data[data.length - 1][i]
          ).trim()}`
        );
      } else {
        result.push(removeNewlines(data[data.length - 2][i]).trim());
      }
    }

    return result;
  }
};

export const handleMergeCellDataSelected = (data) => {
  // handle corner case handsome table render
  const stack = [];
  for (let i = 0; i < data[data.length - 2]?.length; i++) {
    if (!data[data.length - 2][i]) {
      data[data.length - 2][i] = stack[stack.length - 1];
    } else {
      stack.push(data[data.length - 2][i]);
    }
  }

  return data;
};

export const invertObject = (obj) => {
  let invertedObj = {};
  for (let key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      invertedObj[obj[key]] = key;
    }
  }
  return invertedObj;
};

export const bytesToKB = (bytes) => {
  const kilobytes = bytes / 1024;
  return kilobytes.toFixed(2);
};

export const renderArrayNumber = (num) =>
  new Array(num).fill("").map((_, index) => ++index);

export const convertTableSortOrder = (tableSortOrder) => {
  switch (tableSortOrder) {
    case "ascend":
      return SortType.ASC;
    case "descend":
      return SortType.DESC;
    default:
      return undefined;
  }
};

export const tableSorterChangeHandle = ({ setParams, sorters }) => {
  if (sorters.order) {
    setParams((prev) => ({
      ...prev,
      sort_by: sorters.field,
      order_by: convertTableSortOrder(sorters.order),
      limit: LimitDefault,
    }));
  } else {
    setParams((prev) => {
      return {
        ...prev,
        sort_by: "",
        order_by: "",
      };
    });
  }
};

export const cleanObject = (values) => {
  return Object.fromEntries(
    Object.entries(values).filter(([_, value]) => value !== null)
  );
};
