import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import AppColumnActionOption from "@uni/core/AppColumnActionOption";
import AppCustomModalWrapper from "@uni/core/AppCustomModalWrapper";
import IntlMessages from "@uni/utility/IntlMessages";
import { Tooltip } from "antd";
import useDeleteBulkHistory from "hooks/apis/history/useDeleteBulkHistory";
import useAppConfirmModal from "hooks/useAppConfirmModal";
import { useRef } from "react";
import { useIntl } from "react-intl";
import ViewHistoryModal from "./ViewHistoryModal";

const HistoryColumnActionOption = ({ record }) => {
  const viewHistoryModalRef = useRef();
  const { mutate: mutateDelete } = useDeleteBulkHistory();
  const { contextHolder: contextConfirmDelete, openModal: openConfirmDelete } =
    useAppConfirmModal();
  const { messages } = useIntl();

  const handleDelete = async () => {
    openConfirmDelete({
      title: messages["app.products.deleteProduct"]?.toString(),
      okText: messages["common.delete"]?.toString(),
      cancelText: messages["common.cancel"]?.toString(),
      content: messages["app.coList.deleteCoList.content"]?.toString(),
      onOk: () => {
        mutateDelete({ ids: [record._id] });
      },
    });
  };

  return (
    <>
      <AppColumnActionOption
        items={[
          {
            // scopes: [PERMISSIONS.EDIT_PROJECT],
            label: (
              <Tooltip
                placement="top"
                title={<IntlMessages id="bangke.viewDetail" />}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => viewHistoryModalRef?.current?.openModal()}
                >
                  <InfoCircleOutlined
                    style={{ fontSize: "20px", color: "#0A8FDC" }}
                  />
                </div>
              </Tooltip>
            ),
          },
          {
            // scopes: [PERMISSIONS.DELETE_CO],
            label: (
              <Tooltip
                placement="top"
                title={<IntlMessages id="fta.coList.deleteCoList" />}
              >
                <div style={{ cursor: "pointer" }} onClick={handleDelete}>
                  <DeleteOutlined
                    style={{ fontSize: "20px", color: "#0A8FDC" }}
                  />
                </div>
              </Tooltip>
            ),
          },
        ]}
      />
      <AppCustomModalWrapper ref={viewHistoryModalRef}>
        <ViewHistoryModal data={record} />
      </AppCustomModalWrapper>
      {contextConfirmDelete}
    </>
  );
};

export default HistoryColumnActionOption;
