import {
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import AppButtonActions from "@uni/core/AppButtonAction";
import AppCard from "@uni/core/AppCard";
import AppCustomModalWrapper from "@uni/core/AppCustomModalWrapper";
import AppPageMetadata from "@uni/core/AppPageMetadata";
import AppsContainer from "@uni/core/AppsContainer";
import AppsContent from "@uni/core/AppsContainer/AppsContent";
import AppsHeader from "@uni/core/AppsContainer/AppsHeader";
import AppTableContainer from "@uni/core/AppTableContainer";
import { URL_API } from "@uni/services/endpoint";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import { Pagination } from "antd";
import useGetEcusInvoice from "hooks/apis/ecus/useGetInvoice";
import useGetInvoiceByInvoiceNo from "hooks/apis/ecus/useGetInvoiceByInvoiceNo";
import useAppShowHideTableColumn from "hooks/UseAppShowHideTableColumn";
import EcusTableSearch from "pages/data/Exim/Ecus/EcusTableSearch";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { LimitDefault, OffsetDefault } from "shared/constants/AppConst";
import DocsFormModal from "./DocsFormModal";
import DocsFormAProvider from "./DocsFormModal/DocsFormAProvider";
import DocsFormModalImport from "./DocsFormModalImport";
import DocsFormModalImportProvider from "./DocsFormModalImport/DocsFormModalImportProvider";
import "./index.style.less";
import useGetFormAColumn from "./useGetFormAColumn";
import useGetViewFormAFieldConfig from "./ViewFormAModal/useGetViewFormAByNo";

const FORM_A_TABLE_KEY = "FormATable";
const FormA = () => {
  const { companyManagement } = useLayoutContext();
  const { messages } = useIntl();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedGoodsRow, setSelectedGoodsRow] = useState([]);
  const queryClient = useQueryClient();
  const isOpenModal = useRef();
  const isOpenModalImport = useRef();

  const formAColumns = useGetFormAColumn();

  const viewFormAColumns = useGetViewFormAFieldConfig();
  const [params, setParams] = useState({
    limit: LimitDefault,
    offset: OffsetDefault,
    search: "",
  });

  const { data, isFetching } = useGetEcusInvoice({
    ...params,
    folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
    company_slug: companyManagement?.company?.detail?.company?.slug || "",
  });
  const { filteredTableColumns } = useAppShowHideTableColumn({
    tableKey: FORM_A_TABLE_KEY,
    tableColumns: formAColumns,
  });

  const { data: dataDetail, isLoading } = useGetInvoiceByInvoiceNo({
    invoice_no: selectedRowIds?.[0],
    folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
    company_slug: companyManagement?.company?.detail?.company?.slug || "",
  });

  const [columnsState, setColumnsState] = useState(() =>
    viewFormAColumns?.map((column, i) => ({
      ...column,
      key: `${i}`,
      onHeaderCell: () => ({
        id: `${i}`,
        draggable: column.draggable !== false,
      }),
      onCell: () => ({
        id: `${i}`,
      }),
    }))
  );
  return (
    <>
      <AppPageMetadata title={messages["sidebar.fta.formA"]} />
      <AppCard
        title={messages["sidebar.fta.formA"]}
        className="single-tab-card-header"
      >
        <div className="table-container">
          <div className="choosing-table">
            <AppsContainer type="bottom" fullView>
              <AppsHeader>
                <div className="page-header">
                  <EcusTableSearch
                    onValuesChange={(_changedValues, allValues) => {
                      setParams((prev) => {
                        return {
                          ...prev,
                          ...allValues,
                          offset: OffsetDefault,
                        };
                      });
                    }}
                  />

                  <AppButtonActions
                    // scopes={[PERMISSIONS.CREATE_TAG]}
                    icon={<ReloadOutlined />}
                    title={messages["common.refresh"]?.toString()}
                    type="text"
                    onClick={() => {
                      queryClient.invalidateQueries(
                        URL_API.ECUS.GET_ONE_INVOICE
                      );
                    }}
                  />
                </div>
              </AppsHeader>
              <AppsContent
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <AppTableContainer
                  loading={isFetching}
                  data={data?.results}
                  columnsState={filteredTableColumns}
                  setColumnsState={() => {}}
                  columns={filteredTableColumns}
                  rowClassName={(record) => {
                    return record.invoice_no === selectedRowIds?.[0]
                      ? "table-row-selected"
                      : "";
                  }}
                  rowKey="invoice_no"
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        setSelectedRowIds([record.invoice_no]);
                      },
                    };
                  }}
                  style={{ cursor: "pointer", height: "100%" }}
                  scroll={{ x: "max-content", y: "calc(100vh - 270px)" }}
                />
              </AppsContent>
              <div className="custom-pagination">
                <Pagination
                  defaultPageSize={20}
                  current={Math.ceil(params.offset / params.limit) + 1}
                  onChange={(page, pageSize) => {
                    setParams((prev) => {
                      return {
                        ...prev,
                        limit: pageSize,
                        offset: (page - 1) * pageSize,
                      };
                    });
                  }}
                  total={data?.count}
                  size="small"
                />
              </div>
            </AppsContainer>
          </div>
          <div className="result-table">
            <AppsContainer type="bottom" fullView>
              <AppsHeader>
                <div className="page-header">
                  <div className="page-header-right">
                    <AppButtonActions
                      icon={<UploadOutlined />}
                      title={messages["common.import"]}
                      type="primary"
                      onClick={() => isOpenModalImport.current?.openModal()}
                    />
                    <AppButtonActions
                      icon={<PlusOutlined />}
                      title={messages["common.generate"]}
                      type="primary"
                      onClick={() => isOpenModal.current?.openModal()}
                      disabled={!selectedGoodsRow.length}
                    />
                  </div>
                </div>
              </AppsHeader>
              <AppsContent
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <AppTableContainer
                  loading={isLoading}
                  data={dataDetail?.results}
                  columns={viewFormAColumns}
                  columnsState={columnsState}
                  setColumnsState={setColumnsState}
                  rowSelection={{
                    type: "checkbox",
                    onChange: (_, selectedRows) =>
                      setSelectedGoodsRow(selectedRows),
                  }}
                  className="table-container-information"
                  scroll={{ x: "max-content", y: "calc(100vh - 270px)" }}
                />
              </AppsContent>
            </AppsContainer>
          </div>
        </div>

        <DocsFormAProvider>
          <AppCustomModalWrapper ref={isOpenModal}>
            <DocsFormModal selectedGoodsRow={selectedGoodsRow} />
          </AppCustomModalWrapper>
        </DocsFormAProvider>
        <DocsFormModalImportProvider>
          <AppCustomModalWrapper ref={isOpenModalImport}>
            <DocsFormModalImport selectedGoodsRow={selectedGoodsRow} />
          </AppCustomModalWrapper>
        </DocsFormModalImportProvider>
      </AppCard>
    </>
  );
};

export default FormA;
