import { useGenerateContext } from "@uni/utility/AppContextProvider/GenerateContextProvider";
import React, { lazy, useCallback, useState } from "react";
// import { createSpreadsheetData } from "handsontable/helpers";
import "handsontable/dist/handsontable.full.css";
import { Button, Tabs } from "antd";
const Bangke = lazy(() => import("../BangKe"));
import * as XLSX from "xlsx-js-style";
import { DownloadOutlined } from "@ant-design/icons";
import useAppConfirmModal from "hooks/useAppConfirmModal";
import IntlMessages from "@uni/utility/IntlMessages";
import useSavePostBangke from "hooks/apis/bangke/usePostSaveBangke";
import { useLayoutContext } from "@uni/utility/AppContextProvider/LayoutContextProvider";
import { useQueryClient } from "react-query";
import { URL_API } from "@uni/services/endpoint";

const StepThree = () => {
  const {
    result,
    prevStep,
    nextStep,
    setModifyData,
    formData,
    setCurrentStep,
  } = useGenerateContext();
  const { contextHolder: contextConfirmBack, openModal: openConfirmBack } =
    useAppConfirmModal();
  const { companyManagement } = useLayoutContext();
  const queryClient = useQueryClient();

  const [activeKey, setActiveKey] = useState("0");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const handleModify = () => {
    setModifyData(result?.data);
    nextStep();
  };

  const handleConfirmBack = useCallback(() => {
    openConfirmBack({
      title: <IntlMessages id="bangke.backConfirm" />,
      okText: <IntlMessages id="common.confirm" />,
      cancelText: <IntlMessages id="common.cancel" />,
      onOk: () => {
        prevStep();
      },
    });
  }, [openConfirmBack, prevStep]);

  const { mutateAsync } = useSavePostBangke();
  const handleSave = async () => {
    const filterData = result.data.filter(
      (item) => item?.bangke_data?.length > 0
    );
    const newBody = filterData?.map((item) => {
      return {
        ...item,
        bangke_type: formData.url_option,
      };
    });

    await mutateAsync({
      data: newBody,
      folder_slug: companyManagement?.company?.detail?.folder?.slug || "",
      company_slug: companyManagement?.company?.detail?.company?.slug || "",
    });

    queryClient.invalidateQueries(URL_API.ECUS.GET_ONE_INVOICE);
    setCurrentStep(4);
  };
  return (
    <>
      <Tabs activeKey={activeKey} onChange={handleTabChange}>
        {result?.data?.map((item, index) => {
          return (
            <Tabs.TabPane
              tab={
                <span
                  style={{
                    color:
                      activeKey == index
                        ? "#0A8FDC"
                        : item?.message_error
                        ? "red"
                        : "black",
                  }}
                >
                  {item?.ecus_input?.ecus_code}
                </span>
              }
              key={index}
            >
              <Bangke bangkeData={item} />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "8px",
          paddingTop: "24px",
          height: "100%",
          alignItems: "end",
        }}
      >
        <Button type="default" onClick={handleConfirmBack}>
          <IntlMessages id="common.back" />
        </Button>
        <Button type="primary" onClick={handleModify}>
          <IntlMessages id="common.modify" />
        </Button>
        <Button type="primary" onClick={handleSave}>
          <IntlMessages id="common.complete" />
          &nbsp; &&nbsp; <IntlMessages id="common.save" />
        </Button>
      </div>
      {contextConfirmBack}
    </>
  );
};

export default StepThree;
